<template>
  <el-dialog :title="title" :visible="visible" width="30%" :before-close="handleCancel">
    <el-form :model="form" :rules="rules" ref="modalFrom" label-width="100px" size="small">
      <el-form-item prop="roleId" v-show="false">
        <el-input disable v-model="form.id"></el-input>
      </el-form-item>
      <el-form-item label="敏感词：" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import omit from 'omit.js';
import TableModalMinix from '@/minixs/TableModalMinix';

export default {
  name: 'EditGradeModal',
  mixins: [TableModalMinix],
  data() {
    return {
      visible: false,
      title: '',
      defaultForm:{
         id: '',
        name: ''
      },
      form: {
        id: '',
        name: ''
      },
      url: {
        add: 'misc-web-api/admin/word/addWord',
        update: 'misc-web-api/admin/word/updateWord'
      },
      rules: {
        name: [
          { required: true, message: '请输入敏感词', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    add() {
      this.title = '新增';
      this.visible = true;
    },
    edit(row) {
      this.title = '编辑';
      this.form.id = row.id;
      this.form.name = row.name;
      this.visible = true;
    },

    async handleOk() {
      await this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            await this.handleModalUpdate({ data: { ...this.form } });
            this.handleCancel();
          } else {
            const data = omit(this.form, ['id']);
            await this.handleModalAdd({ data });
          }
          this.$emit('modalFormOk');
        }
      });
      console.log('ok');
    }
  }
};
</script>

<style scoped></style>
